@keyframes spinning {
	from {
		transform: "rotate(0deg)",
	}
	to {
		transform: "rotate(360deg)",
	}
}

@keyframes pulse {
	0% {
		transform: "scale(0.95)",
	}
	70% {
		transform: "scale(1)",
	}
	100% {
		transform: "scale(0.95)",
	}
}
